import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import Intro from 'ui-kit/intro';
import Section from 'ui-kit/section';
import Stage from 'ui-kit/stage';
import { Headline, Highlight, P } from 'ui-kit/typography';

import Layout from '../../components/layout';
import TeaserSection from '../../components/teaser-section';

const GlossaryPage = ({ data }) => {
    const stageImage = data.stageImage.childImageSharp;
    const glossary = data.allGlossaryJson.edges.map(({ node }) => node);
    return (
        <Layout description="Glossar">
            <Stage
                image={stageImage}
                imageAlt="Kinderhand hält einen Strauß Wildblumen"
                tag="Kontakt & Service | Glossar"
                headline={{ text: 'Wir helfen Ihnen gerne weiter', level: 'h1' }}
                bottomShade
            />
            <Section>
                <Intro
                    title="Glossar - Wichtige Fachbegriffe kurz erklärt"
                    cta={<Highlight>In unserem Glossar erfahren Sie es.</Highlight>}
                >
                    Wissen Sie, was Färsen sind? Und was hat Abhängen mit der Veredelung von Fleisch
                    zu tun?
                </Intro>
            </Section>
            <Section bg="gray" container="l">
                {glossary.map((glossaryEntry, index) => (
                    <React.Fragment key={glossaryEntry.title}>
                        <Headline level="h3" gap="s">
                            {glossaryEntry.title}
                        </Headline>
                        <P gap={index !== glossary.length - 1 ? 'xxl' : null}>
                            {glossaryEntry.text}
                        </P>
                    </React.Fragment>
                ))}
            </Section>
            <TeaserSection boxes={['originAndQuality', 'recipes']} bg="white" />
        </Layout>
    );
};

GlossaryPage.propTypes = {
    data: PropTypes.shape({
        stageImage: PropTypes.object,
        allGlossaryJson: PropTypes.object,
    }).isRequired,
};

export const query = graphql`
    query {
        stageImage: file(
            relativePath: { eq: "service-and-contact-pages/buehne-service-und-kontakt.jpg" }
        ) {
            childImageSharp {
                fluid(maxWidth: 1920, quality: 80) {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
        allGlossaryJson {
            edges {
                node {
                    title
                    text
                }
            }
        }
    }
`;

export default GlossaryPage;
